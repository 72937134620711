import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { Image } from 'mui-image';
import bgImage1 from './bgImage1.webp'; // Import your images
import video2 from './video2.webp';
import useWindowSize from './WindowSize';
import CustomButton from './CustomButton';

const HomePage = () => {
  const { width, height } = useWindowSize();
  const isMobile = width < 600; // Adjust the breakpoint as needed
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
      }}
    >
      {/* Left half of the page on laptops */}
      <Box
        sx={{
          flex: 1,
          height: '100vh',
          width:'100vw',
          position: 'relative',
          backgroundImage: `url(${bgImage1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        
        }}
      >
        
        {/* Text with background */}
        <Box
          sx={{
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            
            textAlign: 'center',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex:'1',
          }}
        >
          {isMobile ? (
            <div style={{position:'relative', margin:'1vw'}}>              
              <Typography fontSize='8vw' sx={{ color: '#5DB3E6' }}>Slite Badhotell</Typography>
              <Typography fontSize="4vw">Upptäck den historiska charmen på vårt renoverade hotell som ligger i en vacker 150-årig kalkpatronsgård.</Typography>
              <Typography fontSize="4vw"> Fem minuter från Slites underbara badstrand och natursköna strövområden.</Typography>
              <br/>
              <Typography style={{fontWeight:'bold', fontSize:'4vw'}}>Vi öppnar upp dörrarna i februari 2024 och kommer ta väl hand om dig.</Typography>
            </div>
          ) : (
            <div style={{position:'relative', margin:'1vh'}}>


              <Box style={{marginBottom:'0vh'}}>
                <Typography fontSize='4vh' sx={{ color: '#5DB3E6' }}>Slite Badhotell</Typography>
                <Typography fontSize="2.5vh">Upptäck den historiska charmen på vårt renoverade hotell, belägen i en 150 år gammal vacker kalkpatronsgård med en brokig och spännande historia. Med sitt utmärkta läge i bruksorten Slite, bjuder vi in dig till en fin vistelse med nära till allt på norra Gotland.</Typography>  
                <Typography fontSize="2.5vh">Fem minuter bort hittar du Slites underbara badstrand och mycket vackra strövområden där du kan blicka ut över och besöka Gotlands enda skärgård.</Typography>
                <br/>
                <Typography style={{fontSize:'2.5vh', fontWeight:'bold'}}>Vi öppnar upp dörrarna i februari 2024 och kommer ta väl hand om dig.</Typography>
              </Box>
            </div>
          )}
        </Box>
      </Box>

      {/* Right half of the page on laptops with video background */}
      <Box
        sx={{
          flex: 1,
          height: '100vh',
          position: 'relative',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${video2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
       {/* <video
          autoPlay
          muted
          loop
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            zIndex: 0,
          }}
        >
          <source src={video2} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        {/* Button on top of the video */}
        
          <CustomButton href="#Boka" width="25vw" height="5vh" label={"BOKA"} textColor={"white"} bgColor={'#099FBE'} border={`0px none transparent`}/>          
        
      </Box>
    </Grid>
  );
};

export default HomePage;

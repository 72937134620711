import React, { useState } from 'react'
import{AppBar, Box, Grid, Tab, Toolbar, Typography, useTheme, useMediaQuery} from '@mui/material'
import Tabs from '@mui/material/Tabs';
import DrawerComp from './DrawerComp';
import CustomButton from './CustomButton';
import englishFlag from './english.png';
import germanFlag from './german.png'

const Navbar = ({menuItems}) => {
    const theme = useTheme();
    console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    console.log(isMatch);
    const [value, setValue] = useState();
    return(
        
        <AppBar sx={{backgroundImage:'linear-gradient(90deg, rgba(9,159,190,1) 0%, rgba(9,159,190,1) 43%, rgba(9,70,121,1) 100%)'}}>
            <Toolbar>
               {isMatch? <>
                <Typography>
                    <strong>Slite</strong><br/><strong>Badhotell</strong>
                </Typography>
                <DrawerComp links={menuItems}/>
               </> :
                <Grid sx={{placeItems:'center'}} container>
                    <Grid item xs={2}>
                        <Typography>
                        <strong>Slite</strong><br/><strong>Badhotell</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Tabs indicatorColor='secondary' textColor='inherit' value={value} onChange={(e,val)=>setValue(val)}>
                            {menuItems.map((link, index) => (
                                <Tab key={index} label={<strong>{link}</strong>} sx={{ textTransform: 'none', fontSize:'2vh' }} href={`#${link}`}/>
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={3}>
                        <Box display="flex">
                            <CustomButton href="https://slitebadhotell-se.translate.goog/?_x_tr_sl=sv&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp" width="4vw" height="5vh" bgImage={englishFlag} textColor='white'/> 
                            <CustomButton href="https://slitebadhotell-se.translate.goog/?_x_tr_sl=sv&_x_tr_tl=de&_x_tr_hl=en&_x_tr_pto=wapp" width="4vw" height="5vh" bgImage={germanFlag} textColor='white'/>
                        </Box>
                    </Grid>
                </Grid>
                }
            </Toolbar>
        </AppBar>
        
        
    )
}

export default Navbar